<template>
    <div id="news-mobile">
        <div class="head">
            <van-nav-bar title="碳问正文" left-text="返回" left-arrow @click-left="onClickLeft" @click-right="onClickRight">
                <template #right>
                    <van-icon name="ellipsis" size="18" />
                </template>
            </van-nav-bar>
        </div>
        <div class="contain">
            <div class="title">
                {{ detailInfo.title }}
            </div>
            <div class="source">
                <span class="time" @click="handleClickTime">{{
                    detailInfo.send_time
                }}</span>
                <span class="source">
                    <template>
                        来源：
                        {{ detailInfo.source || "--" }}
                    </template>
                </span>
                &nbsp;&nbsp;
                <span class="author">
                    <template>
                        {{ detailInfo.author || "&nbsp;" }}
                    </template>
                </span>
            </div>
            <div class="content" v-html="detailInfo.content">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "News",
    data() {
        return {
            detailInfo: {
                title: "",
                content: "",
                send_time: "",
                source: "",
                link: "",
            },
            visiblepop: false,
        };
    },
    created() {
        // this.getNewsDetail()
    },
    activated() {
        this.detailInfo = {
            ...this.$options.data().detailInfo,
        };
        this.getNewsDetail();
        this.getTags();
    },
    methods: {
        getTags()
        {
            if (!this.$route.query.id) return;
            if (this.$store.state.token){
            setTimeout(async ()=>{
                await this.api.postFormAPISM(
                {
                    article_id: Number(this.$route.query.id),
                },
                '/article/settag'
            )
            },15000)
        }
        },
        // 返回
        onClickLeft() {
            this.$router.back();
        },
        onClickRight() {
            console.log(1);
        },
        collectClose() {
            console.log(1);
            this.visiblepop = !this.visiblepop;
        },
        // 分享微博
        shareWeiBo() {
            window.open(
                "https://service.weibo.com/share/share.php?url=" +
                encodeURIComponent(window.location.href) +
                "&title=" +
                this.detailInfo.title
            );
        },
        getNewsDetail() {
            // http://192.168.23.15:8000/article/home
            // http://192.168.23.15:8000/article/getarticleinfo
            if (!this.$route.query.id) return;
            this.api
                .postFormAPISM(
                    {
                        // page: this.$route.query.page,
                        // limit: 1,
                        article_id: this.$route.query.id,
                    },
                    // '/article/home',
                    "/article/getarticleinfo"
                )
                .then((res) => {
                    console.log("getNewsDetail", res.data);
                    let temp = res.data.data.article_info;
                    temp.content = this.replaceImage(temp.content);
                    this.detailInfo = temp;
                    document.title = this.detailInfo.title;
                })
                .catch((error) => {
                    console.log("error", error);
                });
        },
        replaceImage(content) {
            let reg = /({{[^}}]+.(bmp|jpg|png|tif|gif|pcx|tga|exif|fpx|svg|psd|cdr|pcd|dxf|ufo|eps|ai|raw|WMF|webp|avif|apng)}})/g;
            let reg2 = /^{{([^}}]+)}}$/;
            let reg3 = /<br>[\s\r\n]*<br>[\s\r\n]*<br>/g; //替换三个或更多br为一个
            let temp = content.replace(reg3, "<br><br>").replace(/^<br>/, "");
            // let temp2 = content.replace(/\r/g, '###r').replace(/\n/g, '###n').replace(/\s/g, '###s');
            // console.log('temp', temp2);
            let tempImage = temp.match(reg);
            // // console.log('replaceImage', tempImage);
            if (!tempImage) return temp;
            for (let i = 0; i < tempImage.length; i++) {
                let url = reg2.exec(tempImage[i]);
                if (url instanceof Array) {
                    url = url[1];
                    temp = temp.replace(tempImage[i], imageTemplate(ImageBasicUrl + url));
                }
            }
            return temp;
        },
        handleClickTime() {
            let a = document.createElement("a");
            a.target = "_blank";
            a.href = this.detailInfo.link;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        handleWechatClick() {
            // console.log('handleWechatClick', this.$refs.qrcode_ref, window.location.href);
            if (!this.$refs.qrcode_ref) {
                setTimeout(() => {
                    this.handleWechatClick();
                }, 300);
            } else {
                this.generateQrcode();
            }
        },
        generateQrcode() {
            console.log("generateQrcode");
            QRCode.toCanvas(this.$refs.qrcode_ref, window.location.href, function (
                error
            ) {
                if (error) {
                    return console.error(error);
                }
                console.log("success!");
            });
        },
    },
};
</script>

<style lang="less">
// #layout-mobile {
//     height: 100%;
//     padding: 20px 0 0;
// }
#news-mobile {
    & .contain {
        padding: 10px 20px 80px;

        & .title {
            font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", sans-serif;
            font-style: bold !important;
            font-size: 18px;
            color: #00000f;
        }

        & .source {
            margin-top: 15px;
            margin-bottom: 15px;
            font-size: 10px;
            color: #a7a6a6;
        }
    }
}
</style>
